<template>
  <div class="play-container">
    <div class="course-center">
      <div class="course-title">
        <div class="back" @click="onRoute('PersonalCourse')"> <i class="font-icon">《</i>返回课程</div>
        <!-- <div class="title" v-if="courseChapterList.length">{{ courseChapterList[activeIndex].title}}{{currentVideo.title}}</div> -->
      </div>
      <div class="player-container">
        <video-player
          class="vjs-custom-skin"
          ref="videoPlayer"
          :playsinline="true"
          :options="playerOptions"
          @timeupdate="onPlayerTimeupdate($event)"
          @pause="onPlayerPause($event)"
          @play="onPlayerPlay($event)"
          @loadeddata="onPlayerLoadeddata($event)"
          @waiting="onPlayerWaiting($event)"
          @ready="onPlayerReadied"
          @ended="onPlayerEnded($event)"
        ></video-player>
      </div>
    </div>
    <div class="course-right">
      <div class="course-progress">
        <div class="txt">已学习{{ courseLearn }}%</div>
        <el-progress
          class="line"
          :show-text="false"
          :stroke-width="5"
          :percentage="courseLearn"
          v-if="courseLearn>=0"
        ></el-progress>
      </div>
      <el-collapse v-model="activeIndex" class="course-list">
        <el-collapse-item
          v-for="(item, index) in courseChapterList"
          :key="index"
          :name="index"
        >
          <template slot="title">
            <div class="title" @click="onChangeTab(index)">{{ item.courseChapterTitle }}</div>
          </template>
          <div class="item-content" v-for="(sec,m) in item.courseSectionList" :key="'sec'+m">
            <div class="sec-title">{{ sec.courseSectionTitle}}</div>
            <div
              class="item"
              v-for="(ele, index) in sec.courseVideoList"
              :class="{'play':currentVideo.courseVideoId == ele.courseVideoId,'disable':!ele.isCan}"
              :key="'video' + index"
              v-if="sec.courseVideoList.length"
              @click="changeVideo(ele)"
            >
              <div class="num">{{ ele.number}}</div>
              <div class="status">
                <img
                  src="@/assets/icons/icon-play.png"
                  v-if="currentVideo.courseVideoId == ele.courseVideoId"/>
                <img src="@/assets/icons/icon-clock.png" class="status-img" v-else-if="!ele.isCan"/>
                <img src="@/assets/icons/icon-copyright.png" v-else alt=""/>
              </div>
              <div class="name">{{ ele.title }}
                <div class="finish-status">{{ele.isCan | filterStatus}}</div>
              </div>
              <div class="time">{{ ele.longTime | formatTime }}</div>
            </div>
          </div>
        </el-collapse-item>
      </el-collapse>
    </div>
  </div>
</template>

<script>
import { videoPlayer } from 'vue-video-player';
import 'video.js/dist/video-js.css'
import "vue-video-player/src/custom-theme.css";
export default {
  filters:{
    filterStatus:function(status){
      var map={
        0:'',
        1:'（已完成）',
        2:'（学习中）',
      }
      return map[status]
    },
    formatTime: function (duration) {
      let secondTime = parseInt(duration);// 秒
      let minuteTime = 0;// 分
      let hourTime = 0;// 小时
      if (secondTime > 60) { // 如果秒数大于60，将秒数转换成整数
        // 获取分钟，除以60取整数，得到整数分钟
        minuteTime = parseInt(secondTime / 60);
        // 获取秒数，秒数取佘，得到整数秒数
        secondTime = parseInt(secondTime % 60)
        // 如果分钟大于60，将分钟转换成小时
        if (minuteTime > 60) {
          // 获取小时，获取分钟除以60，得到整数小时
          hourTime = parseInt(minuteTime / 60);
          // 获取小时后取佘的分，获取分钟除以60取佘的分
          minuteTime = parseInt(minuteTime % 60);
        }
      }
      let result = secondTime > 9 ? secondTime : ('0' + secondTime);
      if (minuteTime > 0) {
        result = (minuteTime > 9 ? minuteTime : ('0' + minuteTime)) + ":" + result;
      } else {
        result = "00" + ":" + result;
      }
      if (hourTime > 0) {
        result = (hourTime > 9 ? hourTime : ('0' + hourTime)) + ":" + result;
      } else {
        result = "00" + ":" + result;
      }
      return result;
    }
  },
  components: {
    videoPlayer
  },
  data() {
    return {
      tips: false,
      activeIndex: [],
      ipType: this.$route.query.ipType ? this.$route.query.ipType : 1,
      cId: this.$route.query.cId ? this.$route.query.cId : "",
      playerOptions: {
        playbackRates:false, // 可选的播放速度
        autoplay: false, // 如果为true,浏览器准备好时开始回放。
        muted: false, // 默认情况下将会消除任何音频。
        loop: false, // 是否视频一结束就重新开始。
        preload: "auto", // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
        language: "zh-CN",
        aspectRatio: "16:9", // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
        fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
        sources: [
          {
            type: "video/mp4", // 类型
            src: "http://yoncc.com/storage/video/20211015/1634286249365645.mp4", // url地址
          },
        ],
        poster: "", // 封面地址
        notSupportedMessage: "此视频暂无法播放，请稍后再试", // 允许覆盖Video.js无法播放媒体源时显示的默认信息。
        controlBar: {
          timeDivider: true, // 当前时间和持续时间的分隔符
          durationDisplay: true, // 显示持续时间
          remainingTimeDisplay: false, // 是否显示剩余时间功能
          fullscreenToggle: false, // 是否显示全屏按钮
        },
      },
      currentVideo: {
        title:''
      },
      courseChapterList: [],
      pauseTime: 0,
      userInfo: {},
      countPerTime:null,
      postSetInter:null,
      time_playing:0,
      onlineLearnedTime:1,
      courseLearn:0,
    };
  },
  computed: {
    player() {
      return this.$refs.videoPlayer.player;
    },
    totalPro() {
      let numbers = 0;
      this.courseChapterList.forEach((item) => {
        item.courseSectionList
        numbers += item.learn;
      });
      return parseInt(numbers / this.courseChapterList.length);
    },
  },
  created() {
    this.getCourseList();
    this.userInfo = JSON.parse(localStorage.getItem("userInfo"));
  },
  mounted() {
    window.addEventListener(
      "visibilitychange",
      () => {
        if(!this.player.pause()){return false};
        if(this.tips == 1 || this.tips == 2){
          this.player.pause();
        }
        
        // if (this.tips == 2) {
        //   console.log('visibilitychange')
        //   this.player.pause();
        // }
      },true);
      this.postSetInter = setInterval(() => {
        if(this.tips==1 || this.tips == 2){
          this.studyCourseVideo()
        }
      }, 50000);
    // window.οnbefοreunlοad = (e) => {
    //   e = e || window.event;
    //   if (e) {
    //     e.returnValue = "关闭提示";
    //   }
    //   window.open('www.baidu.com')
    //   return "关闭提示";
    // };
  },
  destroyed () {
    // 如果定时器在运行则关闭
    if (this.countPerTime) {
      clearInterval(this.countPerTime)
      clearInterval(this.postSetInter)
    }
  },
  methods: {
    getCourseList() {
      this.$http
        .get("/api/web/course/getMemberCourseInfo?ipType=" + this.ipType)
        .then((res) => {
          const courseChapterList = res.data.courseChapterList;
          this.courseChapterList = courseChapterList;
          this.courseLearn=res.data.courseLearn;
          if (!!this.cId) {
            const index = courseChapterList.findIndex(
              (item) => this.cId == item.courseChapterId
            );
            if(!this.activeIndex.includes(index)){
              this.activeIndex.push(index)
            }
            // console.log(index)
            // this.activeIndex = index;
            console.log(courseChapterList[index].courseSectionList[0].courseVideoList[0])
            this.currentVideo = courseChapterList[index].courseSectionList[0].courseVideoList[0];
            
            this.playerOptions.poster=this.currentVideo.image
            this.playerOptions.sources[0].src = this.currentVideo.video;
          }
        });
    },
    onChangeTab(i){console.log(i)},
    changeVideo(item) {
      !!this.countPerTime && clearInterval(this.countPerTime);
      this.countPerTime=null;
      if(item.isCan){
        this.onlineLearnedTime=1;
        this.currentVideo = item;
        this.playerOptions.poster=item.image
        this.playerOptions.sources[0].src = item.video;
        setTimeout(()=>{
          if(this.player){
            this.player.play();
          }
        },500)
        // this.playerOptions.autoplay = true;
      }
      
    },
    onPlayerPlay(player) {
      this.tips = 1;
      this.pauseTime = false;
      this.cacheHandler(player);
    },
    //视频加载时候的函数
    onPlayerLoadeddata(player) {
      console.log('进入加载')
      this.cacheHandler(player); //缓存视频的方法
      this.pauseTime = true; // 视频暂停计时的函数，还原功能无效，计时功能中有效
    },
    /*
     * 视频缓存进度的方法，还原进度时间的方法
     * 为了确保，视频显示的位置，视频长度和缓存的时间做比较，在此期间，视频未必先加载出来，
     * */
    cacheHandler(player) {
      const playedTime =JSON.parse(localStorage.getItem(
        `player_${this.currentVideo.courseVideoId}_${this.userInfo.memberId}_playing`
      ));
      let videoTotalTime = this.currentVideo.longTime; //视频时长
      if(!player)return;
      if (playedTime) {
        // console.log('进入存在缓存')
        player.currentTime(playedTime.videoLearnedTime < videoTotalTime ? playedTime.videoLearnedTime : 0);
        this.onlineLearnedTime=this.currentVideo.learnTime<=playedTime.onlineLearnedTime?playedTime.onlineLearnedTime:this.currentVideo.learnTime;
        localStorage.setItem(
          `player_${this.currentVideo.courseVideoId}_${this.userInfo.memberId}_playing`,
          JSON.stringify({
            videoLearnedTime:playedTime.videoLearnedTime,
            onlineLearnedTime:this.onlineLearnedTime || 0
          })
        );
      }else{
        player.currentTime(this.currentVideo.playedTime < videoTotalTime ? this.currentVideo.playedTime : 0);
        localStorage.setItem(
          `player_${this.currentVideo.courseVideoId}_${this.userInfo.memberId}_playing`,
          JSON.stringify({
            videoLearnedTime:this.currentVideo.playedTime,
            onlineLearnedTime:this.currentVideo.learnTime || 0
          })
        );
      }
      
    },
    // goCacheProgress(){
    //   const playedTime =JSON.parse(localStorage.getItem(
    //     `player_${this.currentVideo.courseVideoId}_${this.userInfo.memberId}_playing`
    //   ));
    //   let videoTotalTime = this.currentVideo.longTime; //视频时长
    //   if (playedTime) {
    //     // console.log('进入存在缓存')
    //     player.currentTime(playedTime.videoLearnedTime < videoTotalTime ? playedTime.videoLearnedTime : 0);
    //     this.onlineLearnedTime=this.currentVideo.learnTime<=playedTime.onlineLearnedTime?playedTime.onlineLearnedTime:this.currentVideo.learnTime;
    //     localStorage.setItem(
    //       `player_${this.currentVideo.courseVideoId}_${this.userInfo.memberId}_playing`,
    //       JSON.stringify({
    //         videoLearnedTime:playedTime.videoLearnedTime,
    //         onlineLearnedTime:this.onlineLearnedTime || 0
    //       })
    //     );
    //   }
    // },
    onPlayerReadied(){
      
    },
    //此函数不点击开始都会运行的
    onPlayerTimeupdate(player) {
      console.log('进入时间更新')
      this.pauseTime = false;
      const src = player.currentSrc();
      //实时写入数据
      const playing =JSON.parse(localStorage.getItem(
        `player_${this.currentVideo.courseVideoId}_${this.userInfo.memberId}_playing`
      ));
      if(this.tips == 1 || this.tips == 2){
        let currentTime=~~(this.player.currentTime());
        localStorage.setItem(
          `player_${this.currentVideo.courseVideoId}_${this.userInfo.memberId}_playing`,
          JSON.stringify({
            videoLearnedTime:currentTime,
            onlineLearnedTime:this.onlineLearnedTime || 0
          })
        );
        this.currentVideo.learnTime=this.onlineLearnedTime;
      }
      
      if(this.onlineLearnedTime < this.currentVideo.longTime && this.tips == 1){
        // console.log(this.onlineLearnedTime)
        // console.log(this.tips)
        // console.log(this.countPerTime)
        if(!this.countPerTime) {this.perTimeHandler()}
        this.tips = 2; 
      }else if(this.onlineLearnedTime >= this.currentVideo.longTime){
        !!this.countPerTime && clearInterval(this.countPerTime);
        this.tips = 2;
      }
    },
    //每秒记载在线学习时间
    perTimeHandler() {
      this.countPerTime = setInterval(() => {
        if(!this.pauseTime && this.onlineLearnedTime<this.currentVideo.longTime){
          let videoTotalTime = this.currentVideo.longTime;
          // console.log(this.countPerTime)
          this.onlineLearnedTime++;
          this.currentVideo.learnTime=this.onlineLearnedTime;
          // this.currentVideo.learn=this.onlineLearnedTime<videoTotalTime?this.onlineLearnedTime:videoTotalTime;
        }
      }, 1000);
    },
    onPlayerWaiting() {
      this.pauseTime = true;
      console.log('等待了一下')
    },
    onPlayerPause() {
      this.pauseTime = true;
      this.tips = 2; //暂停的时候 tips 变2了
      // console.log('进入暂停')
      !!this.countPerTime && clearInterval(this.countPerTime); //清除计时
      this.countPerTime=null;
      this.studyCourseVideo();
    },
    //播放结束时，请求一次后台接口
    onPlayerEnded(player) {
      this.studyCourseVideo();
      this.countPerTime && clearInterval(this.countPerTime);
    },
    studyCourseVideo() {
      let play_time=this.player.currentTime()?~~this.player.currentTime():'';
      if(!this.currentVideo.isCan){
        return
      }
      const data = {
        learnTime: this.currentVideo.learnTime,
        playTime:play_time,
        courseVideoId: this.currentVideo.courseVideoId,
      };
      this.$http
        .post("/api/web/course/studyCourseVideo", data)
        .then((res) => {
          const {courseChapterId,courseSectionId,courseVideoId,video,courseLearn}=res.data;
          this.courseLearn=courseLearn;
          if(courseVideoId){
            this.currentVideo.isCan=1;
            const courseChapterList=this.courseChapterList;
            const index = courseChapterList.findIndex(
              (item) =>courseChapterId == item.courseChapterId
            );
            // this.activeIndex=index;
            const sindex = courseChapterList[index].courseSectionList.findIndex(
              (item) =>courseSectionId == item.courseSectionId
            );
            const vindex = courseChapterList[index].courseSectionList[sindex].courseVideoList.findIndex(
              (item) =>courseVideoId == item.courseVideoId
            );
            this.courseChapterList[index].courseSectionList[sindex].courseVideoList[vindex].isCan=2;
            this.courseChapterList[index].courseSectionList[sindex].courseVideoList[vindex].video=video;
            
            // this.$toast('本节课时学习完成')
            // this.changeVideo(this.courseChapterList[index].videoList[sindex])
          }
        });
    },
    onRoute(name){
      this.$router.push({name})
    },
  },
};
</script>

<style lang='less' scoped>
.play-container {
  display: flex;
  background: #2f3236;
  padding-bottom: 5px;
  /deep/.vjs-custom-skin {
    .video-js .vjs-big-play-button {
      width: 2.5em;
      height: 2.5em !important;
      font-size: 2.5em;
      line-height: 2.5em !important;
      border-radius: 100%;
    }
    &:hover .video-js .vjs-big-play-button {
      background-color: rgba(232, 12, 10, 0.5) !important;
    }
    .vjs-control-bar{
      height: 3.5em;
      padding: 0 1em;
      background: linear-gradient(
      180deg, hsla(0, 0%, 100%, 0), rgba(0, 0, 0, 0.5));
      align-items: center;
      .vjs-volume-panel.vjs-volume-panel-horizontal{
        position: absolute;
        right: 20px;
        bottom: 10px;
        width: 9em;
        .vjs-volume-control{
          width: 5em !important;
          height: 3.5em !important;
          opacity: 1 !important;
          .vjs-volume-level{
            background: #d71a18;
          }
          .vjs-volume-bar{
            margin-top: 1.6em;
          }
        }
      }
      .vjs-time-control {
        height: 3.5em;
      }
      .vjs-play-control{
        .vjs-icon-placeholder::before{
          width: 32px;
          height: 32px;
          line-height: 32px;
          border-radius: 50%;
          border: 1px solid #fff;
          top: 6px;
        }
        
      }
      .vjs-progress-control.vjs-control{
        position: absolute;
        bottom: 3.5em;
        left: 0;
        width: 100%;
        .vjs-progress-holder{
          height: 6px;
          border-radius:6px ;
        }
        .vjs-load-progress{
          border-radius: 6px;
        }
        .vjs-play-progress{
          border-radius: 6px;
          background: #d71a18;
        }
        
        .vjs-play-progress:before{
          border-radius: 50%;
          border: solid 2px #d71a18;
        }
        
      }
    }
  }
  .course-center {
    flex: 1;
    .course-title {
      display: flex;
      padding: 28px 19px;
      color: #fff;
      .back {
        font-size: 16px;
        cursor: pointer;
        .font-icon{
          font-style: normal;
          margin-right: 10px;
        }
      }
      .title {
        flex: 1;
        font-size: 18px;
        font-weight: 700;
        text-align: center;
      }
    }
  }
  .course-right {
    width: 400px;
    height: 935px;
    overflow-y: scroll;
    .course-progress {
      padding: 32px 30px 28px;
      font-size: 14px;
      color: #fff;
      text-align: left;
      .txt {
        margin-bottom: 24px;
      }
      
    }
    /deep/.course-list {
      height: calc(100% - 108px);
      overflow-y:scroll ;
      padding: 30px 20px;
      background: #3f4449;
      border: none;
      &::-webkit-scrollbar {
        width: 10px !important;
        height: 100%;
        background: rgba(0,0,0,0.1);
      }
      &::-webkit-scrollbar-thumb {
      border-radius:10px;
      background:rgba(0,0,0,0.1);
      -webkit-box-shadow:inset 0 0 6px rgba(0,0,0,0.5);
      }
      &::-webkit-scrollbar-thumb:window-inactive {
      background:rgba(255,0,0,0.4);
      }
      .el-collapse-item {
        margin-bottom: 20px;
      }
      .el-collapse-item__header {
        display: flex;
        align-items: center;
        padding: 20px;
        height: auto;
        min-height: 50px;
        background: #232323;
        color: #fff;
        border-bottom: none;
        .title {
          width: 260px;
          line-height: 24px;
          display: flex;
          justify-content: space-between;
        }
        .el-collapse-item__arrow {
          font-size: 20px;
          font-weight: 700;
          margin: 0 0 0 auto;
        }
      }
      .el-collapse-item__wrap {
        background: none;
        color: #fff;
        border-bottom: none;
        .el-collapse-item__content {
          color: #fff;
          font-size: 14px;
          padding-bottom: 0;
        }
        .item-content{
          .sec-title{
            display: flex;
            padding-top: 10px;
            padding-left: 30px;
          }
        }
        .item {
          display: flex;
          align-items: center;
          height: 49px;
          padding: 0 2px 0 30px;
          border-bottom: 1px solid #666;
          cursor: pointer;
          &:last-child {
            border-bottom: none;
          }
          &.play {
            color: #d71a18;
          }
          &.disable {
            color: #9199a1;
            cursor: auto ;
          }
          .num {
            margin-right: 20px;
          }
          .status {
            display: flex;
            align-items: center;
            margin-right: 9px;
            .status-img{
              width: 18px;
              margin-left: -1px;
            }
          }
          .name {
            display: flex;
            align-items: center;
            width: 180px;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            text-align: left;
            .finish-status{
              font-size: 12px;
            }
          }
          .time {
            width: 60px;
            margin-left: auto;
          }
        }
      }
    }
  }
}
</style>
